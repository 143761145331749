export const GET_PLAN_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_PLAN_SUCCESS';
export const GET_PLAN_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_PLAN_FAILURE';
export const GET_PLAN_RESET = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_PLAN_RESET';
export const GET_PLAN_START = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_PLAN_START';
export const GET_ALL_LOOKUPS_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-NEW-PROJECT/GET_ALL_LOOKUPS_SUCCESS';
export const GET_ALL_LOOKUPS_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-NEW-PROJECT/GET_ALL_LOOKUPS_FAILURE';
export const ADD_NEW_PROJECT_START = 'CBRE-PJM-CAPITAL-PLAN-NEW-PROJECT/ADD_NEW_PROJECT_START';
export const ADD_NEW_PROJECT_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-NEW-PROJECT/ADD_NEW_PROJECT_SUCCESS';
export const ADD_NEW_PROJECT_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-NEW-PROJECT/ADD_NEW_PROJECT_FAILURE';
export const ADD_NEW_PROJECT_EXISTS_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-NEW-PROJECT/ADD_NEW_PROJECT_EXISTS_FAILURE';
export const PUSH_PROJECT_TO_CLIENT_START = 'CBRE-PJM-CAPITAL-PLAN-NEW-PROJECT/PUSH_PROJECT_TO_CLIENT_START';
export const PUSH_PROJECT_TO_CLIENT_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-NEW-PROJECT/PUSH_PROJECT_TO_CLIENT_SUCCESS';
export const PUSH_PROJECT_TO_CLIENT_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-NEW-PROJECT/PUSH_PROJECT_TO_CLIENT_FAILURE';
export const CLOSE_NEW_PROJECT_TOAST = 'CBRE-PJM-CAPITAL-PLAN-NEW-PROJECT/CLOSE_NEW_PROJECT_TOAST';
export const RESET_NEW_PROJECT_STATUS = 'CBRE-PJM-CAPITAL-PLAN-NEW-PROJECT/RESET_NEW_PROJECT_STATUS';
export const RESET_NEW_PROJECT_ERROR = 'CBRE-PJM-CAPITAL-PLAN-NEW-PROJECT/RESET_NEW_PROJECT_ERROR';
export const RESET_FETCH_PROJECT_DETAILS = 'CBRE-PJM-CAPITAL-PLAN-NEW-PROJECT/RESET_FETCH_PROJECT_DETAILS';
export const GET_PROJECT_BY_ID_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-NEW-PROJECT/GET_PROJECT_BY_ID_SUCCESS';
export const GET_PROJECT_BY_ID_SUCCESS_PREVIEW = 'CBRE-PJM-CAPITAL-PLAN-NEW-PROJECT/GET_PROJECT_BY_ID_SUCCESS_PREVIEW';
export const SET_LOADING_TRUE = 'CBRE-PJM-CAPITAL-PLAN-NEW-PROJECT/SET_LOADING_TRUE';
export const SET_LOADING_FALSE = 'CBRE-PJM-CAPITAL-PLAN-NEW-PROJECT/SET_LOADING_FALSE';
export const GET_PROPERTY_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-NEW-PROJECT/GET_PROPERTY_SUCCESS';
export const GET_PROJECT_BY_ID_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-NEW-PROJECT/GET_PROJECT_BY_ID_FAILURE';
