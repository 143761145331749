import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  scheduleId: 0,
  isLoading: false,
  error: false,
  messageCode: '',
  message: '',
  alert: '',
  alertMessage: '',
  cptFileDetails: {
    isLoading: false,
  },
  fetchCptFilesDetails: false,
};

export const importCptSlice = createSlice({
  name: 'importCpt',
  initialState: initialState,
  reducers: {
    getScheduleIdSuccess: (state, { payload: { scheduleId } }) => {
      state.scheduleId = scheduleId;
    },
    getCptFilesDetailsStart: (state) => {
      state.cptFileDetails.isLoading = true;
      state.fetchCptFilesDetails = false;
    },
    getCptFilesDetailsSuccess: (
      state,
      { payload: { totalRecords, successCount, failedCount, inProcessCount, records } },
    ) => {
      state.cptFileDetails.totalRecords = totalRecords;
      state.cptFileDetails.isLoading = false;
      state.cptFileDetails.successCount = successCount;
      state.cptFileDetails.failedCount = failedCount;
      state.cptFileDetails.inProcessCount = inProcessCount;
      state.cptFileDetails.records = records;
    },
    importCptStart: (state) => {
      state.isLoading = true;
      state.error = false;
    },
    importCptSuccess: (state) => {
      state.isLoading = false;
      state.alert = 'success';
      state.alertMessage = 'CPT file imported successfully';
      state.fetchCptFilesDetails = true;
    },
    importCptFailure: (state) => {
      state.isLoading = false;
      state.alert = 'failure';
      state.alertMessage = 'CPT file imported failed';
      state.fetchCptFilesDetails = false;
    },
    closeAlert: (state) => {
      state.alert = '';
      state.alertMessage = '';
    },
  },
});

export const {
  getCptFilesDetailsStart,
  getCptFilesDetailsSuccess,
  importCptStart,
  importCptSuccess,
  importCptFailure,
  getScheduleIdSuccess,
  closeAlert,
} = importCptSlice.actions;

export default importCptSlice.reducer;
