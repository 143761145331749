import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Axios from 'axios';

const SEGMENTS = [
  {
    id: 5,
    title: 'PJM Advisory',
    displayName: 'PJM Advisory',
    isSelected: false,
    uniqueCode: 'AD',
  },
  {
    id: 6,
    title: 'PJM Enterprise',
    displayName: 'PJM Enterprise',
    isSelected: false,
    uniqueCode: 'EA',
  },
  {
    id: 7,
    title: 'PJM Local',
    displayName: 'PJM Local',
    isSelected: false,
    uniqueCode: 'LA',
  },
];

const useFetchSegmentData = () => {
  const [userHierarchyAccess, setUserHierarchyAccess] = useState(null);
  const [segmentArray, setSegmentArray] = useState([]);
  const [clientArray, setClientArray] = useState([]);
  const [isAccessLoading, setIsAccessLoading] = useState(true);
  const [segmentHasChanged, setSegmentHasChanged] = useState(false);
  const prevSegmentIdRef = useRef(null);
  const [projectOnlyAccess, setProjectOnlyAccess] = useState(false);
  const { isSystemAdmin } = useSelector((state) => state.auth);

  const clientConfig = useSelector((state) => state.config);
  const hierarchyUrl = clientConfig?.client?.accessManagementBaseUrl ?? '';
  const coreUrl = clientConfig?.client?.coreUrl ?? '';

  const initialSegment = localStorage.getItem('selectedSegment');
  const initialClient = localStorage.getItem('selectedClient');

  const parsedSegment = initialSegment && initialSegment !== "undefined" ? JSON.parse(initialSegment) : null;
  const parsedClient = initialClient && initialClient !== "undefined" ? JSON.parse(initialClient) : null;

  const [selectedSegment, setSelectedSegment] = useState(parsedSegment);
  const [selectedClient, setSelectedClient] = useState(parsedClient);

  const handleSegmentChange = (updatedSegments) => {
    const activeSegment = updatedSegments.find((segment) => segment.isSelected);
    if (activeSegment) {
      setSelectedSegment(activeSegment);
      setSegmentHasChanged(prevSegmentIdRef.current !== activeSegment.id);
      prevSegmentIdRef.current = activeSegment.id;
    }
    localStorage.setItem('selectedSegment', JSON.stringify(activeSegment));
    setSegmentArray(updatedSegments);
  };

  const handleClientChange = (updatedClient) => {
    setSelectedClient(updatedClient);
    localStorage.setItem('selectedClient', JSON.stringify(updatedClient));
  };

  const fetchAccess = async () => {
    try {
      const response = await Axios.get(`${hierarchyUrl}user-access`, {
        headers: {
          'x-authorization': sessionStorage.getItem('pjm-token'),
          'x-locale': localStorage.getItem('locale') || 'en_US',
        },
      });
      setUserHierarchyAccess(response.data);
      return response.data;
    } catch (error) {
      setUserHierarchyAccess(null);
      console.error('Failed to fetch user hierarchy access information', error);
    }
  };

  const fetchClientData = async () => {
    try {
      const response = await Axios.get(`${hierarchyUrl}filter-data?pjmSegmentId=6&pjmSegmentFullAccess=true`, {
        headers: {
          'x-authorization': sessionStorage.getItem('pjm-token'),
          'x-locale': localStorage.getItem('locale') || 'en_US',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch client data', error);
      return null;
    }
  };

  useEffect(() => {
    const fetchAccessAndLookupData = async () => {
      setIsAccessLoading(true);
      const accessData = await fetchAccess();
      const clientData = await fetchClientData();

      if (accessData === undefined || accessData === null) {
        setIsAccessLoading(false);
        return;
      }

      // Extracting unique segmentParentId's
      const { accessProfileDetail } = accessData;
      const { segmentParentIds, clientParentIds } = [
        'region',
        'sector',
        'country',
        'division',
        'businessUnit',
        'client',
        'project',
      ].reduce(
        (acc, key) => {
          if (accessProfileDetail[key]) {
            accessProfileDetail[key].forEach((item) => {
              if (item.segmentParentId && !acc.segmentParentIds.includes(item.segmentParentId)) {
                acc.segmentParentIds.push(item.segmentParentId);
              }
              if (item.clientParentId && !acc.clientParentIds.includes(item.clientParentId)) {
                acc.clientParentIds.push(item.clientParentId);
              }
            });
          }
          return acc;
        },
        { segmentParentIds: [], clientParentIds: [] },
      );

      // Check if user ONLY has project access by checking each key in accessProfileDetail for length and see if ONLY Project has length
      const hasOnlyProjectAccess = Object.keys(accessProfileDetail).every(
        (key) => key === 'project' || !accessProfileDetail[key].length,
      );
      if (hasOnlyProjectAccess) {
        setProjectOnlyAccess(true);
      }

      let segmentList = SEGMENTS.filter((segment) => segmentParentIds.includes(segment.id));

      if (isSystemAdmin) {
        segmentList = SEGMENTS;
      }
      setSegmentArray(segmentList);

      // If there are no segments available, in the sense user has no access to any resources,
      // then removing the selected segment from sessionStorage and setting the selectedSegment to null
      if (segmentList.length === 0) {
        sessionStorage.removeItem('selectedSegment');
        setSelectedSegment(null);
      }
      // If user has access only one segment, then set it as the active segment and update the segment array
      else if (segmentList.length === 1) {
        const activeSegment = { ...segmentList[0], isSelected: true };
        handleSegmentChange([activeSegment]);
      }

      let clientList = clientData?.data
        .filter((client) => clientParentIds.includes(client.clientId))
        .map(({ clientId, clientName }) => ({ clientId, clientName }));

      if (isSystemAdmin && clientList.length === 0) {
        clientList = [{ clientId: 31, clientName: 'CBRE Demo' }];
      }
      setClientArray(clientList);

      // if user has access to one client or more than one client,
      // then, default the selectedClient to first client in the array
      if (clientList.length) {
        const defaultClient = localStorage.getItem('selectedClient')
          ? JSON.parse(localStorage.getItem('selectedClient'))
          : clientList[0];
        handleClientChange(defaultClient);
      } else {
        sessionStorage.removeItem('selectedClient');
        setSelectedClient(null);
      }

      setIsAccessLoading(false);
    };

    fetchAccessAndLookupData();
  }, [hierarchyUrl, coreUrl]);

  useEffect(() => {
    if (userHierarchyAccess) {
      const { accessProfileDetail } = userHierarchyAccess;

      // Check project-only access for the selected segment and client
      if (selectedSegment) {
        const inHierarchy = ['region', 'sector', 'country', 'division', 'businessUnit', 'client'].some((key) =>
          accessProfileDetail[key]?.some((item) => item.segmentParentId === selectedSegment?.id),
        );

        const isSelectedSegmentProjectOnly =
          accessProfileDetail.project.some((item) => item.segmentParentId === selectedSegment?.id) && !inHierarchy;

        let isSelectedClientProjectOnly = false;
        if (selectedSegment?.id === 6 && selectedClient) {
          const clientInHierarchy = ['region', 'country', 'division', 'businessUnit', 'client'].some((key) =>
            accessProfileDetail[key]?.some((item) => item.clientParentId === selectedClient.clientId),
          );

          isSelectedClientProjectOnly =
            accessProfileDetail.project.some((item) => item.clientParentId === selectedClient.clientId) &&
            !clientInHierarchy;
        }

        if ((selectedSegment?.id === 6 && isSelectedClientProjectOnly) || isSelectedSegmentProjectOnly) {
          setProjectOnlyAccess(true);
        } else {
          setProjectOnlyAccess(false);
        }
      } else {
        setProjectOnlyAccess(false);
      }
    }
  }, [userHierarchyAccess, selectedSegment, selectedClient]);

  useEffect(() => {
    if (
      !isAccessLoading &&
      userHierarchyAccess?.hasVendorOnlyAccess &&
      Array.isArray(userHierarchyAccess.vendorAccessViews)
    ) {
      const { vendorAccessViews } = userHierarchyAccess;

      // Check if current selections are valid for vendor access
      const isCurrentSelectionValid = vendorAccessViews.some(
        (view) => view.segmentParentId === selectedSegment?.id && view.client === selectedClient?.clientId,
      );

      // Only update if current selection is invalid
      if (!isCurrentSelectionValid) {
        const vendorView = vendorAccessViews[0];
        if (vendorView) {
          const { client: clientId, segmentParentId } = vendorView;
          // Update selectedSegment and selectedClient
          const updatedSegment = SEGMENTS.find((segment) => segment.id === segmentParentId);
          if (updatedSegment) {
            handleSegmentChange([{ ...updatedSegment, isSelected: true }]);
          }
          const activeClientObj = clientArray.find((item) => item.clientId === clientId);
          handleClientChange(activeClientObj);
        }
      }
    }
  }, [userHierarchyAccess, isAccessLoading]);


  return {
    isAccessLoading,
    userHierarchyAccess,
    segmentArray,
    clientArray,
    selectedSegment,
    selectedClient,
    segmentHasChanged,
    projectOnlyAccess,
    handleSegmentChange,
    handleClientChange,
  };
};

export default useFetchSegmentData;