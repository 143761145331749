/* eslint-disable */
/*export const checkPermissions = (userPermissions, allowedPermissions, pjmAppCode) => {
  if (allowedPermissions.length === 0 || userPermissions.isSystemAdmin || (userPermissions.appAdmin && userPermissions.appAdmin[pjmAppCode])) {
    return true;
  }
  if (!userPermissions[pjmAppCode]) {
    return false;
  }
  return userPermissions[pjmAppCode].some((permission) => allowedPermissions.includes(permission)
  );
};*/
export const checkPermissions = (userPermissions, allowedPermissions, pjmAppCode, clientId) => {
  if (
    allowedPermissions.length === 0 ||
    userPermissions.isSystemAdmin ||
    (userPermissions.appAdmin && userPermissions.appAdmin[pjmAppCode])
  ) {
    return true;
  }
  if (!userPermissions[pjmAppCode]) {
    return false;
  }
  if (clientId) {
    const userAppClientPermissions = userPermissions[pjmAppCode].find((elem) => {
      return elem.clientId == clientId;
    });
    return (
      userAppClientPermissions &&
      userAppClientPermissions.permissions.some((permission) => allowedPermissions.includes(permission))
    );
  }
  const userAppPermissions = userPermissions[pjmAppCode];

  const allAppPermissions = [...new Set(userAppPermissions.map((a) => a.permissions).reduce((p, c) => p.concat(c)))];
  return allAppPermissions.some((permission) => allowedPermissions.includes(permission));
};

const downloadFile = async (downloadUrl = '') => {
  const options = {
    headers: new Headers({
      'Content-Type': 'application/json',
      'x-authorization': sessionStorage.getItem('pjm-token'),
      'x-locale': localStorage.getItem('locale') || 'en_US',
    }),
  };
  const response = await fetch(downloadUrl, options);
  return response.blob();
};

export const handleMediaDownload = async ({ downloadUrl, fileName }) => {
  const blob = await downloadFile(downloadUrl);
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.append(link);
  link.click();
  link.remove();
};
