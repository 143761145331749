/* eslint-disable */
/* eslint-disable no-extra-parens,global-require */
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { COLLAPSED_DRAWER, FIXED_DRAWER, MINI_DRAWER } from 'modules/cbre-pjm-Common/constants/ActionTypes';
import { switchLanguage, toggleCollapsedNav, updateLocaleSelection } from 'modules/cbre-pjm-Common/actions/Setting';
import { getMyActionsProjectListUrl } from 'modules/cbre-pjm-pds-my-actions/ducks/selectors';
import { getFusionProjects } from 'modules/cbre-pjm-pds-my-actions/ducks/actions';
import IntlMessages from 'util/IntlMessages';
import Avatar from '@material-ui/core/Avatar';
import UserInfoPopup from 'components/UserInfo/UserInfoPopup';
import HelpInfoPopup from 'components/HelpInfo/HelpInfoPopup';
import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { WHITE } from 'modules/cbre-pjm-Common/constants/ThemeColors';
import Badge from '@material-ui/core/Badge';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { getLogOutUrl, updateUserLocaleUrl } from 'modules/cbre-pjm-Common/selectors';
import SideBarContent from '../../../SideBar/SideBarContent';
import { defaultLang, languageData as language } from 'components/LanguageSwitcher/data';
import { Track } from 'containers/Services/AnalyticsProvider';
import PromptOnOrgChange from 'modules/principal-lite/cbre-pjm-principal-lite-new-project/src/components/PromptOnOrgChange';
import OrganizationDrawer from '../../../OrganizationDrawer';
import { UserHierarchyAccessContext } from 'containers/Services/UserHierarchyAccessProvider';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import DomainIcon from '@material-ui/icons/Domain';
import WorkIcon from '@material-ui/icons/Work';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import Button from '@material-ui/core/Button';
import { hasVendorRestrictions } from 'util/utilityMethods';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(0),
  },
  iconButton: {
    color: '#fff',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
  label: {
    fontSize: '0.875rem',
    verticalAlign: 'bottom',
    paddingLeft: '5px',
  },
}));

// eslint-disable-next-line complexity
const Index = (props) => {
  const { location, hideMenu, logOutUrl, fusionProjectsListUrl, isFusion } = props;
  const dispatch = useDispatch();
  const [languageData, setLanguageData] = useState(language);
  const [prevUrl, setPrevUrl] = useState(null);
  const { navCollapsed } = useSelector(({ common }) => common);
  const { userDisplayName, userEmailId, authUser } = useSelector(({ auth }) => auth);
  const userLocale = localStorage.getItem('locale');
  const localeUrl = useSelector(updateUserLocaleUrl);
  const moduleName = 'cbre-pjm-pds-my-actions';
  const { totalRecords } = useSelector((state) => state[moduleName]);
  const showLanguageSelection = true; // location.pathname.indexOf('cpp') === 1;
  const { drawerType, locale, themeColor } = useSelector(({ settings }) => settings);
  const [userInfo, setUserInfo] = useState(false);
  const [helpInfo, setHelpInfo] = useState(false);
  const isDashboard = location.pathname === '/dashboard';
  const { selectedSegment, selectedClient, segmentArray, clientArray, userHierarchyAccess } = useContext(UserHierarchyAccessContext);
  const [orgDrawerOpen, setOrgDrawerOpen] = useState(false);
  const isTabletScreen = useMediaQuery('(max-width:1200px)');
  const classes = useStyles();
  const isMyProjects = location?.pathname?.startsWith('/myprojects');
  const [isVendorOnlyAccess, setIsVendorOnlyAccess] = useState(false);

  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const currentURL = new URL(window.location.href);
  const params = new URLSearchParams(window.location.search);
  const mode = params.get('mode');
  const sourceSection = params.get('sourceSection');

  useEffect(() => {
    const currentPath = location.pathname.toLowerCase();
    if (currentPath.includes('programmanagement')) {
      const languageData = [];
      languageData.push(language[0]);
      setLanguageData(languageData);
    }
  }, [location.pathname]);

  const changeLanguage = (selectedLang) => {
    dispatch(switchLanguage(selectedLang));
    document.dispatchEvent(new CustomEvent('languageChange', { detail: selectedLang }));
  };

  useEffect(() => {
    const newUrl = `${fusionProjectsListUrl}?`;
    const fusionProjectsListObj = {
      fusionProjectsListUrl: newUrl,
      fusionProjectsListBaseUrl: newUrl,
      pageIndex: 0,
      isSearching: true,
    };

    const paths = ['pda', 'cpp', '/capitalplan', '/capitalPlan', '/myprojects'];
    if (paths.some((path) => location.pathname.includes(path))) {
      dispatch(getFusionProjects(fusionProjectsListObj));
    }

    const [englishUS, chinese, englishUK, french] = language;
    const onlyUSChineseSupport = [
      'dashboard',
      'pda',
      'capitalplan',
      'estimator',
      'projecttracker',
      'fusion',
      'accessmanagement',
      'myprojects',
    ];
    const allLangSupport = ['cpp'];
    const onlyUSEnglishSupport = ['programmanagement'];

    const updateDropdown = (path, lang) => {
      if (location.pathname.startsWith(prevUrl)) return false;
      const isPathAvailable = path.some((url) => {
        if (location.pathname.toLowerCase().startsWith(`/${url}`)) {
          setPrevUrl(`/${url}`);
          return true;
        }
      });
      if (isPathAvailable) {
        setLanguageData([...lang]);
        const isLocaleAvailable = lang.find((d) => d.locale === userLocale);
        if (!isLocaleAvailable) {
          localStorage.setItem('beforeProgrammaticallyUpdatedLocale', userLocale);
          changeLanguage(defaultLang);
        } else {
          const userSelectedLocale = localStorage.getItem('beforeProgrammaticallyUpdatedLocale');
          if (userSelectedLocale && userSelectedLocale !== userLocale) {
            const getUserSelectedLanguage = lang.find((d) => d.locale === userSelectedLocale);
            if (getUserSelectedLanguage) changeLanguage(getUserSelectedLanguage);
          }
        }
      }
    };
    updateDropdown(allLangSupport, language);
    updateDropdown(onlyUSChineseSupport, [englishUS, chinese]);
    updateDropdown(onlyUSEnglishSupport, [englishUS]);

    // after switching to fusion and locale is not set to en, will set initially to en
    //   if (location.pathname.indexOf('fusion') === 1 && locale.locale !== 'en_US') {
    //     dispatch(switchLanguage(languageData[0]));
    //   }

    //   if(!showLanguageSelection && location.pathname.indexOf('fusion') === -1){
    //     dispatch(switchLanguage(languageData[0]));
    //   }
    //   else if (showLanguageSelection &&  location.pathname.indexOf('fusion') === -1){
    //     const selectedLocale = languageData.filter((lngItem) => lngItem.locale === userLocale);
    //     dispatch(switchLanguage(selectedLocale ? selectedLocale[0] : defaultLang));
    //   }
  }, [location.pathname]);

  useEffect(() => {
    if (userLocale && showLanguageSelection) {
      const selectedLocale = languageData.find((lngItem) => lngItem.locale === userLocale);
      if (selectedLocale && userLocale !== selectedLocale.locale) {
        changeLanguage(selectedLocale);
      }
    }
  }, [userLocale]);

  useEffect(() => {
    // filter out all but pipeline and orderbook from subSectionListContent if VendorOnlyAccess
    if (
      (userHierarchyAccess?.hasVendorOnlyAccess || hasVendorRestrictions(userHierarchyAccess, selectedClient, selectedSegment)) &&
      Array.isArray(userHierarchyAccess.vendorAccessViews) &&
      selectedSegment &&
      selectedClient
    ) {
      setIsVendorOnlyAccess(true);
    } else {
      setIsVendorOnlyAccess(false);
    }
  }, [userHierarchyAccess, selectedSegment, selectedClient]);

  const onUserInfoSelect = () => {
    setUserInfo(!userInfo);
  };
  const onHelpInfoSelect = () => {
    setHelpInfo(!helpInfo);
  };
  const onToggleCollapsedNav = () => {
    const val = !navCollapsed;
    dispatch(toggleCollapsedNav(val));
  };

  const onSwitchLanguage = (event) => {
    const langVal = languageData.find((lngItem) => lngItem.languageId === event.target.value);
    Track('LANGUAGE_SELECTION', { Language: langVal });
    if (langVal) {
      changeLanguage(langVal);
      if (location.pathname.indexOf('fusion') === -1) {
        dispatch(updateLocaleSelection({ url: localeUrl, payload: { locale: langVal.locale } }));
      }
      localStorage.setItem('beforeProgrammaticallyUpdatedLocale', '');
    }
  };

  const sideDrawerStyle = drawerType.includes(FIXED_DRAWER)
    ? 'd-xl-flex'
    : drawerType.includes(COLLAPSED_DRAWER)
      ? ''
      : 'd-flex';
  const logoImage = themeColor.includes(WHITE)
    ? require('assets/images/logo/logo-inverse.svg').default
    : themeColor === 'green' && location.pathname === '/dashboard'
      ? require('assets/images/logo/logo-inverse.svg').default
      : require('assets/images/logo/logo-inverse-white.svg').default;

  let type = 'permanent';
  if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && window.innerWidth < 1200)) {
    type = 'temporary';
  }
  const drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? 'd-block d-xl-none'
    : drawerType.includes(COLLAPSED_DRAWER)
      ? 'd-block'
      : 'd-none';

  const handleDrawerOpen = () => {
    const parts = currentURL?.pathname?.split('/');
    const url = new URL(window.location.href);
    const recentMode = url.searchParams.get('mode');
    const projectUnderEditMode =
      recentMode && recentMode !== 'preview' && (parts?.includes('project') || parts?.includes('newproject'));
    const newOpportunityProject = !recentMode && parts?.includes('newproject');
    const newTemplateProject = !recentMode && sourceSection === 'templates';
    const newPipelineProject = sourceSection === 'pipeline' && (!recentMode || recentMode !== 'preview');
    if (projectUnderEditMode || newOpportunityProject || newPipelineProject || newTemplateProject) {
      setConfirmedNavigation(true);
      setOrgDrawerOpen(false);
    } else {
      setConfirmedNavigation(false);
      setOrgDrawerOpen(true);
    }
  };

  useEffect(() => {
    if (confirmedNavigation) {
      setOrgDrawerOpen(false);
    }
  }, [confirmedNavigation]);

  const handleDrawerClose = () => {
    setOrgDrawerOpen(false);
  };

  const handleCancel = () => {
    setConfirmedNavigation(false);
    setOrgDrawerOpen(true);
  };

  return (
    <AppBar className="app-main-header" elevation={0}>
      {!isVendorOnlyAccess && !hideMenu &&
        !(drawerType.includes(MINI_DRAWER) || (drawerType.includes(FIXED_DRAWER) && window.innerWidth >= 1200)) ? (
        <div className={`app-sidebar d-none ${sideDrawerStyle}`}>
          <Drawer
            className="app-sidebar-content"
            variant={type}
            open={type.includes('temporary') ? navCollapsed : true}
            onClose={onToggleCollapsedNav}
            classes={{
              paper: 'side-nav top-71',
            }}
          >
            {/* <UserInfo/> */}
            <SideBarContent />
          </Drawer>
        </div>
      ) : (
        ''
      )}
      <Toolbar
        disableGutters={false}
        className={`app-toolbar p-3 ${location.pathname === '/dashboard' ? 'app-toolbar-dashboard' : 'app-toolbar-other'
          }`}
      >
        {!isVendorOnlyAccess && !hideMenu && (
          <Fragment>
            <IconButton
              className={`pjm-menu-icon mr-2 ${drawerStyle}`}
              aria-label="Menu"
              onClick={onToggleCollapsedNav}
            >
              <span>
                <i className="zmdi zmdi-menu hamburger-menu-icon"></i>
              </span>
            </IconButton>
          </Fragment>
        )}
        {!hideMenu && (
          <Link className={` app-logo  mr-2 d-none d-sm-block ${!props.hideMenu ? '' : 'hide-menu'}`} to="/dashboard">
            <span className="cbre-title">
              <IntlMessages id="header.cbre" />
            </span>
            <IntlMessages id="header.pjmTechnologyPortal" />
          </Link>
        )}
        {hideMenu && (
          <span className={` app-logo  mr-2 d-none d-sm-block ${!props.hideMenu ? '' : 'hide-menu'}`} to="/">
            <span className="cbre-title">
              <IntlMessages id="header.cbre" />
            </span>
            <IntlMessages id="header.pjmTechnologyPortal" />
          </span>
        )}
        {/* <SearchBox styleName="d-none d-lg-block" placeholder=""
                     onChange={updateSearchText}
                     value={searchText}/> */}
        <ul className="header-notifications list-inline ml-auto">
          {isMyProjects && (
            <li className="list-inline-item mr-2">
              {selectedSegment && selectedSegment?.displayName && (
                <Box mr={2} display="inline-block">
                  <WorkIcon />
                  <span className={classes.label}>{selectedSegment?.displayName}</span>
                </Box>
              )}
              {selectedSegment && selectedSegment?.uniqueCode === 'EA' && (
                <Box mr={2} display="inline-block">
                  <DomainIcon />
                  <span className={classes.label}>{selectedClient?.clientName}</span>
                </Box>
              )}
              {(segmentArray.length > 1 || (selectedSegment?.uniqueCode === 'EA' && clientArray.length > 1)) &&
                (isTabletScreen ? (
                  <IconButton
                    className={classes.iconButton}
                    aria-label="organization"
                    onClick={() => handleDrawerOpen()}
                  >
                    <MenuOpenIcon />
                  </IconButton>
                ) : (
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    endIcon={<MenuOpenIcon />}
                    onClick={() => handleDrawerOpen()}
                  >
                    <span className={classes.label}>Organization</span>
                  </Button>
                ))}
            </li>
          )}
          {!hideMenu && !location.pathname.includes('/dashboard') && (
            <li className="list-inline-item mr-2">
              <Link className={`action-button mr-2 d-block`} to="/pda/myactions">
                <div className={`d-none d-sm-block`}>
                  <IntlMessages id="header.actions" />
                </div>
                <div className={`d-block d-sm-none`}>
                  <NotificationsIcon fontSize="small" />
                </div>

                {totalRecords > 0 && <span>{totalRecords}</span>}
              </Link>
            </li>
          )}
          {(showLanguageSelection || props.isFusion) && (
            <li className="list-inline-item mr-2">
              <FormControl variant="standard" className="w-200 header-language-select">
                <div className="header-language-select-menu">
                  <Select value={locale.languageId} onChange={onSwitchLanguage} className="select-value">
                    {languageData.map((language, index) => {
                      const { name, languageId } = language;
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <MenuItem className="header-language" key={index} value={languageId}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              </FormControl>
            </li>
          )}
          {authUser && authUser !== null && (
            <li className="list-inline-item user-nav header-user-nav mr-2">
              <Dropdown className="quick-menu user-notification" isOpen={userInfo} toggle={onUserInfoSelect}>
                <DropdownToggle className="d-inline-block" tag="span" data-toggle="dropdown">
                  <IconButton className="icon-btn size-32 h-100" disableRipple={true}>
                    <Badge badgeContent="1" color="error" overlap="circular" invisible={true}>
                      <Avatar className="bg-primary size-32">
                        <AccountCircleOutlinedIcon fontSize="large" />
                      </Avatar>
                    </Badge>
                  </IconButton>
                </DropdownToggle>
                <DropdownMenu className="header-user-nav-dropdown" right={true}>
                  <UserInfoPopup
                    logOutUrl={logOutUrl}
                    isFusion={isFusion}
                    userDisplayName={userDisplayName}
                    userEmailId={userEmailId}
                  />
                </DropdownMenu>
              </Dropdown>
            </li>
          )}
          <li className="list-inline-item user-nav help-list-icon cursor-pointer">
            <Dropdown className="quick-menu cursor-pointer" isOpen={helpInfo} toggle={onHelpInfoSelect}>
              <DropdownToggle className="d-inline-block" tag="span" data-toggle="dropdown">
                <HelpOutlineOutlinedIcon className={isDashboard ? 'green-c-dark' : 'white-c'} fontSize="large" />
              </DropdownToggle>
              <DropdownMenu className="header-user-nav-dropdown" right={true}>
                <HelpInfoPopup />
              </DropdownMenu>
            </Dropdown>
          </li>
        </ul>
      </Toolbar>

      <OrganizationDrawer open={orgDrawerOpen} onClose={handleDrawerClose} />

      <PromptOnOrgChange
        confirmedNavigation={confirmedNavigation}
        setConfirmedNavigation={setConfirmedNavigation}
        handleCancel={handleCancel}
      />
    </AppBar>
  );
};
Index.propTypes = {
  hideMenu: PropTypes.bool,
  isFusion: PropTypes.bool,
  logOutUrl: PropTypes.string,
  fusionProjectsListUrl: PropTypes.string,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};
const mapStateToProps = (state) => ({
  logOutUrl: getLogOutUrl(state),
  fusionProjectsListUrl: getMyActionsProjectListUrl(state),
});
export default connect(mapStateToProps)(withRouter(Index));
